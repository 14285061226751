import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import classNames from "classnames";

import { Api } from "../../api";
import FillButton from "../../components/FillButton";

export default function Conversations(props) {
  const history = useHistory();
  let [conversationData, setConversationData] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    const userIds = [props.sourceUser, props.withUser];
    userIds.sort();
    const data = await Api.Messages.GetConversations(props.sourceUser);
    setConversationData(data.data);
  }

  if (!conversationData) {
    return <div className="content-panel">Loading...</div>;
  }

  return (
    <div
      className={classNames({
        "content-panel": true,
        "conversation-list": true,
      })}
    >
      <h2>Conversation List</h2>
      {conversationData.map((message) => {
        return [
          <div key={message.id}>
            {message.title}{" "}
            <FillButton
              onClick={() => {
                history.push(
                  `/users/${props.sourceUser}?tab=3&conversationWith=${message.users[0].userId}`
                );
              }}
            >
              View
            </FillButton>
          </div>,
          <br key={message.id + "l"} />,
        ];
      })}
    </div>
  );
}
