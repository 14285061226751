import {
    AddCommentToPost,
    CreatePost,
    DeleteComment,
    DeletePost,
    DenyNotification,
    GetComment,
    GetCommentReactions,
    GetComments,
    GetCommentsForPost,
    GetPost,
    GetPostReactions,
    GetPosts,
    ReactToComment,
    ReactToPost,
    SendNotification,
    SetPinned,
} from './posts';

export const Posts = {
    GetPosts,
    GetPost,
    GetPostReactions,
    DeletePost,

    GetCommentsForPost,
    GetCommentReactions,
    GetComments,
    GetComment,
    DeleteComment,

    CreatePost,
    AddCommentToPost,

    ReactToPost,
    ReactToComment,

    SetPinned,

    SendNotification,
    DenyNotification,
};
