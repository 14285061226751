import { ApiGet, ApiPost } from "./helpers";

function GetBanReasons() {
  return ApiGet(`contentReport/banReasons`);
}

function GetReport(id) {
  return ApiGet(`contentReport/get/${id}`);
}

function GetReports(contentType, contentId, params) {
  if (!contentType) {
    return ApiGet(`contentReport`, params);
  }
  if (!contentId) {
    return ApiGet(`contentReport/${contentType}`, params);
  }
  return ApiGet(`contentReport/${contentType}/${contentId}`, params);
}

function CloseReport(id) {
  return ApiPost(`contentReport/close/${id}`);
}

function CloseRelatedReports(id, type) {
  return ApiPost(`contentReport/closeRelated/${type}/${id}`);
}

export const Reports = {
  GetBanReasons,
  GetReport,
  GetReports,
  CloseReport,
  CloseRelatedReports,
};
