import './CommentListFilter.css';

import React, { useEffect, useState } from 'react';

import Checkbox from '../../components/Checkbox';

let loadTimer = null;
export default function CommentListFilter(props) {

    const [activeReports, setActiveReports] = useState(false);

    const [deletedComments, setDeletedComments] = useState(false);

    const [disabledUsers, setDisabledUsers] = useState(false);

    useEffect(() => {
        if (loadTimer) {
            clearTimeout(loadTimer);
        }
        loadTimer = setTimeout(() => {
            props.onFilterChange({
                activeReports: activeReports,
                deletedComments: deletedComments,
                disabledUsers: disabledUsers,
            })
        }, 500);
    }, [activeReports, deletedComments, disabledUsers]);

    return (
        <>
            <div className='post-list-filter-container'>

                <div className='post-list-filter-stacked-options'>

                    <Checkbox
                        name='show-active-reports'
                        label='Show only comments with active reports'
                        onChange={setActiveReports} />

                    <Checkbox
                        name='show-deleted'
                        label='Show deleted comments'
                        onChange={setDeletedComments} />

                    <Checkbox
                        name='show-disabled'
                        label='Show disabled (banned or deleted) users'
                        onChange={setDisabledUsers}
                        checked={disabledUsers} />

                </div>

            </div>
        </>
    );
}
