import classNames from 'classnames';
import React from 'react';

export default function Checkbox(props) {

    const {
        name,
        className,
        onChange,
        ...rest
    } = props;

    let classes = { 'checkbox-container': true };
    if (className) {
        classes[className] = true;
    }

    return (
        <div className={classNames(classes)}>
            <input
                type="checkbox"
                id={name}
                name={name}
                {...rest}
                value="Bike"
                onChange={() => {
                    const element = document.getElementById(name);
                    onChange(element.checked);
                }} />

            <label htmlFor={props.name}>{props.label}</label>
        </div>
    );
}
