const AWS_SERVERLESS_CDN = 'https://d3bxpgsojlf8hr.cloudfront.net';

export function createImageUrl(info, width, height) {
    if (!info?.key) {
        return null;
    }

    // In this case, key will be a direct URL
    if (!info.bucket) {
        return info.key;
    }

    const imageEdits = {
        resize: {
            fit: 'cover',
        },
    }

    if (width && height) {
        imageEdits.resize.width = width;
        imageEdits.resize.height = height;
    }

    const imageInfo = {
        bucket: info.bucket,
        key: info.key,
        edits: imageEdits,
    };

    return `${AWS_SERVERLESS_CDN}/${btoa(JSON.stringify(imageInfo))}`;
}
