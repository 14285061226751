import './PostList.css';

import React, { useEffect, useState } from 'react';

import { Api } from '../../api';
import Post from './Post';
import PostListFilter from './PostListFilter';

export default function PostList(props) {
    const [posts, setPosts] = useState([]);

    const [filter, setFilter] = useState({});

    const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);

    function refreshData() {
        const finalFilter = Object.assign({}, filter, props.filter);
        Api.Posts.GetPosts(finalFilter)
            .then(result => setPosts(result.data));
    };

    async function loadNextPage() {
        if (isLoadingNextPage) {
            return;
        }
        setIsLoadingNextPage(true);

        const earlyDate = posts.length > 0 ? posts[posts.length - 1].createTime : undefined;

        const finalFilter = Object.assign({
            before: earlyDate,
        }, filter, props.filter);
        const result = await Api.Posts.GetPosts(finalFilter);

        const newPosts = [
            // Copy all existing posts that we didn't receive a new copy of
            ...posts.filter(p => result.data.find(r => r.id === p.id) === undefined),
            // Include new items
            ...result.data]
            .sort((a, b) => b.createTime - a.createTime);

        setPosts(newPosts);

        setIsLoadingNextPage(false);
    }

    function _onScroll(event) {
        const el = document.getElementById('post-list-list');
        if (el.scrollHeight - el.scrollTop - (el.clientHeight * 2) < 0) {
            loadNextPage();
        }
    }

    useEffect(refreshData, [filter]);

    return (
        <div className='post-list-container'>
            <PostListFilter onFilterChange={setFilter} hasUniversity={props.hasUniversity} />
            <div id='post-list-list' className='post-list-list' onScroll={_onScroll}>

                {posts.map(p => <Post {...p} key={p.id} listMode={true} />)}

            </div>
        </div>
    );
}
