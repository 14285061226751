import filterBuySell from '../images/icons/filter-buy-sell.png';
import filterHousing from '../images/icons/filter-housing.png';
import filterLostFound from '../images/icons/filter-lost-found.png';
import filterTravel from '../images/icons/filter-travel.png';
import filterPost from '../images/icons/filter-wall.png';

export const PostType = {
    Post: 'post',

    BuyEventV1: 'buy-event-v1',
    BuyOther: 'buy-other',

    SellEventV1: 'sell-event-v1',
    SellOther: 'sell-other',
    Meta_SellTravel: 'sell-travel',

    TravelLiftShare: 'travel-lift',
    TravelBus: 'travel-bus',
    TravelTrain: 'travel-train',
    TravelOther: 'travel-other',

    Lost: 'lost',
    Found: 'found',

    HousingRoomAvailable: 'housing-room-available',
    HousingLookingForRoom: 'housing-looking-for-room',
}

export const PostTypeGroup = {
    Post: 'post',
    Buy: 'buy',
    Sell: 'sell',
    Meta_BuySell: 'buy-sell',
    Meta_SellTravel: 'sell-travel',
    LostAndFound: 'lost-found',
    Housing: 'housing',
    Travel: 'travel',

    FilterRoot: 'filter',
}

export const CreatePostTypeGroups = [
    { id: PostTypeGroup.Post, },
    {
        id: PostTypeGroup.Meta_BuySell,
        questionText: 'I want to',
        options: [
            { key: PostTypeGroup.Buy, label: 'Buy', childGroup: PostTypeGroup.Buy },
            { key: PostTypeGroup.Sell, label: 'Sell', childGroup: PostTypeGroup.Sell }]
    },
    {
        id: PostTypeGroup.Buy,
        options: [
            { key: PostType.BuyEventV1, label: 'Event tickets' },
            { key: PostType.BuyOther, label: 'Other item' }]
    },
    {
        id: PostTypeGroup.Sell,
        options: [
            { key: PostType.SellEventV1, label: 'Event tickets', },
            { key: PostType.SellOther, label: 'Other item', },
            { key: 'sell-travel', label: 'Travel tickets', childGroup: PostTypeGroup.Meta_SellTravel, }]
    },
    {
        id: PostTypeGroup.Meta_SellTravel,
        options: [
            { key: PostType.TravelBus, label: 'Bus' },
            { key: PostType.TravelTrain, label: 'Train' },
            { key: PostType.TravelOther, label: 'Other' }]
    },
    {
        id: PostTypeGroup.LostAndFound,
        questionText: 'I have',
        options: [
            { key: PostType.Lost, label: 'Lost an item', },
            { key: PostType.Found, label: 'Found an item', }]
    },
    {
        id: PostTypeGroup.Housing,
        questionText: 'I am looking for',
        options: [
            { key: PostType.HousingRoomAvailable, label: 'A housemate', },
            { key: PostType.HousingLookingForRoom, label: 'Housing', }]
    },
    {
        id: PostTypeGroup.Travel,
        questionText: 'I am',
        options: [
            { key: PostType.TravelLiftShare, label: 'Arranging lift share', },
            { key: PostType.TravelBus, label: 'Selling bus tickets', },
            { key: PostType.TravelTrain, label: 'Selling train tickets', },
            { key: PostType.TravelOther, label: 'Selling other travel', }]
    },
];

export const FilterPostTypeGroupRoot = {
    id: PostTypeGroup.FilterRoot,
    options: [
        { key: PostType.Post, label: 'Uni Chat', icon: filterPost, },
        { key: PostTypeGroup.Meta_BuySell, label: 'Buy & Sell', childGroup: PostTypeGroup.Meta_BuySell, icon: filterBuySell, },
        { key: PostTypeGroup.Housing, label: 'Housing', childGroup: PostTypeGroup.Housing, icon: filterHousing, },
        { key: PostTypeGroup.LostAndFound, label: 'Lost & Found', childGroup: PostTypeGroup.LostAndFound, icon: filterLostFound, },
        { key: PostTypeGroup.Travel, label: 'Travel', childGroup: PostTypeGroup.Travel, icon: filterTravel, iconSize: 35, },
    ]
};

export const FilterPostTypeGroups = [
    FilterPostTypeGroupRoot,
    { id: PostTypeGroup.Post },
    {
        id: PostTypeGroup.Meta_BuySell,
        options: [
            { key: PostTypeGroup.Buy, label: 'Buying', childGroup: PostTypeGroup.Buy, },
            { key: PostTypeGroup.Sell, label: 'Selling', childGroup: PostTypeGroup.Sell, },
        ]
    },
    {
        id: PostTypeGroup.Buy,
        options: [
            { key: PostType.BuyEventV1, label: 'Event tickets' },
            { key: PostType.BuyOther, label: 'Other' },
        ]
    },
    {
        id: PostTypeGroup.Sell,
        options: [
            { key: PostType.SellEventV1, label: 'Event tickets', },
            { key: PostType.SellOther, label: 'Other', },
        ]
    },
    {
        id: PostTypeGroup.LostAndFound,
        options: [
            { key: PostType.Lost, label: 'Lost Items', },
            { key: PostType.Found, label: 'Found Items', },
        ]
    },
    {
        id: PostTypeGroup.Housing,
        options: [
            { key: PostType.HousingRoomAvailable, label: 'Available rooms', },
            { key: PostType.HousingLookingForRoom, label: 'Looking for house', },
        ]
    },
    {
        id: PostTypeGroup.Travel,
        options: [
            { key: PostType.TravelLiftShare, label: 'Lift shares', },
            { key: PostType.TravelBus, label: 'Bus tickets', },
            { key: PostType.TravelTrain, label: 'Train tickets', },
            { key: PostType.TravelOther, label: 'Other', },
        ]
    },
];

export class PostTypeUtil {

    static fromString(value) {
        switch (value) {
            default:
                throw 'Invalid post type ' + value;

            case undefined: return undefined;

            case 'post': return PostType.Post;

            case 'buy-event-v1': return PostType.BuyEventV1;
            case 'buy-other': return PostType.BuyOther;

            case 'sell-event-v1': return PostType.SellEventV1;
            case 'sell-other': return PostType.SellOther;
            case 'sell-travel': return PostType.Meta_SellTravel;

            case 'travel-lift': return PostType.TravelLiftShare;
            case 'travel-bus': return PostType.TravelBus;
            case 'travel-train': return PostType.TravelTrain;
            case 'travel-other': return PostType.TravelOther;

            case 'lost': return PostType.Lost;
            case 'found': return PostType.Found;

            case 'housing-room-available': return PostType.HousingRoomAvailable;
            case 'housing-looking-for-room': return PostType.HousingLookingForRoom;
        }
    }

    static IsTravel(type) {
        return type === PostType.TravelLiftShare
            || type === PostType.TravelBus
            || type === PostType.TravelTrain
            || type === PostType.TravelOther;
    }

    static IsLostAndFound(type) {
        return type === PostType.Lost
            || type === PostType.Found;
    }

    static IsHousing(type) {
        return type === PostType.HousingRoomAvailable
            || type === PostType.HousingLookingForRoom;
    }

    static IsSell(type) {
        return type === PostType.SellEventV1
            || type === PostType.SellOther;
    }

    static IsBuy(type) {
        return type === PostType.BuyEventV1
            || type === PostType.BuyOther;
    }

    static IsBuySellEvent(type) {
        return type === PostType.BuyEventV1
            || type === PostType.SellEventV1;
    }

    static HasImages(type) {
        return type
            && !PostTypeUtil.IsTravel(type)
            && type !== PostType.HousingLookingForRoom
            && type !== PostType.SellEventV1
            && type !== PostType.BuyEventV1;
    }

    static HasTitle(type) {
        return PostTypeUtil.IsLostAndFound(type)
            || type === PostType.BuyOther
            || type === PostType.SellOther;
    }

    static HasCustomTitle(type) {
        return type === PostType.BuyEventV1
            || type === PostType.SellEventV1;
    }

    static HasTextLocation(type) {
        return PostTypeUtil.IsLostAndFound(type)
            || PostTypeUtil.IsHousing(type);
    }

    static HasStartTime(type) {
        return PostTypeUtil.IsTravel(type);
    }

    static HasEndTime(type) {
        return PostTypeUtil.IsLostAndFound(type)
            || PostTypeUtil.IsTravel(type)
            || PostTypeUtil.IsBuySellEvent(type);
    }

    static HasCost(type) {
        return PostTypeUtil.IsTravel(type)
            || type === PostType.SellEventV1
            || type === PostType.SellOther;
    }

    static FriendlyName(type) {
        switch (type) {
            default: return 'TODO';

            case PostType.TravelLiftShare: return 'Lift Share';
            case PostType.TravelBus: return 'Bus';
            case PostType.TravelTrain: return 'Train';
            case PostType.TravelOther: return 'Other';

            case PostType.HousingRoomAvailable: return 'Room Available';
            case PostType.HousingLookingForRoom: return 'Looking for Room';

            case PostType.Lost: return 'Lost';
            case PostType.Found: return 'Found';
        }
    }

    static CanReact(type) {
        return true;
    }

    static CanComment(type) {
        return true;
    }

    static CanShare(type) {
        return false;
    }
}
