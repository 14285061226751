import { Data } from "./data";
import { Posts } from "./posts";
import { Registrations } from "./registrations";
import { Reports } from "./reports";
import { GetUniversities, UpdateUniversityLocation } from "./universities";
import { Users } from "./users";
import { Messages } from "./messages";
import * as Locations from "./locations";

export const Api = {
  Users: Users,
  Posts: Posts,
  GetUniversities,
  UpdateUniversityLocation,
  Reports,
  Registrations,
  Data,
  Messages,
  Locations,
};
export * from "./codes";
export * from "./helpers";
