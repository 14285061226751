import * as React from "react";
import ReportListShared from "./ReportListShared";

export default function ReportListAll(props) {
  return (
    <ReportListShared
      onlyActive={props.onlyActive}
      type="all"
      userId={props.userId}
      showType={true}
      showActions={props.showActions}
    />
  );
}
