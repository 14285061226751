import React, { useEffect, useState, useRef } from "react";
import "./Conversation.css";

import { Api } from "../../api";
import classNames from "classnames";

export default function Conversation(props) {
  let [conversationData, setConversationData] = useState(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    const userIds = [props.sourceUser, props.withUser];
    userIds.sort();
    const quickFind = `conv-${userIds.join(",")}`;
    const data = await Api.Messages.GetMessages(quickFind, props.sourceUser);
    setConversationData(data.data);
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  }

  if (!conversationData) {
    return <div className="content-panel">Loading...</div>;
  }

  return (
    <div className="content-panel">
      <h2>Conversation with {conversationData.conversationTitle}</h2>
      <h5>Most recent messages last</h5>

      <div className="message-list">
        {conversationData.messages.map((message) => {
          const me = message.userId + "" === props.sourceUser + "";
          return [
            <div
              key={message.id}
              className={classNames({
                message: true,
                "message-me": me,
              })}
            >
              {message.message}
            </div>,
            <br key={message.id + "l"} />,
          ];
        })}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
}
