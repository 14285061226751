import './UserList.css';

import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

import { Api, ApiUrl } from '../../api';
import FillButton from '../../components/FillButton';
import Auth from '../../util/Auth';
import User from './User';
import UserListFilter from './UserListFilter';

export default function UserList(props) {
    const [users, setUsers] = useState([]);

    const [filter, setFilter] = useState({});

    const [emailsShowing, setEmailsShowing] = useState(false);
    const [emails, setEmails] = useState('');

    let token = Auth.session().token;

    function loadData() {
        const finalFilter = Object.assign({}, filter);

        Api.Users.GetUsers(finalFilter)
            .then(result => {
                setUsers(result.data);
            });
    };

    useEffect(loadData, [filter]);

    const downloadFilter = Object.assign({ token: token, client: 'admin-console:1.0.0' }, filter);

    return (
        <div className='user-list-container'>
            <UserListFilter onFilterChange={setFilter} />

            <FillButton onClick={_loadEmails}>Get email addresses</FillButton>

            <div className='user-list-list'>

                {users.map(u => <User key={u.id} {...u} listMode={true} />)}

            </div>

            <Modal
                isOpen={emailsShowing}
                onRequestClose={() => setEmailsShowing(false)}
                contentLabel='Email list'>

                <textarea readOnly={true} value={emails} style={{ width: '500px', height: '500px' }} />

                <br />

                <a href={ApiUrl('/users/csv', downloadFilter)}>Download as CSV</a>

                <FillButton onClick={() => setEmailsShowing(false)}>Close</FillButton>

            </Modal>

        </div>
    );

    function _loadEmails() {
        setEmails('Loading...');
        setEmailsShowing(true);

        const f = Object.assign({ noLimit: true }, filter);
        Api.Users.GetUsers(f)
            .then(result => {
                const emails = result.data.map(u => u.email);
                setEmails(emails.join(';'));
            });
    }
};
