import './Comment.css';

import classNames from 'classnames';
import moment from 'moment';
import React, { PropTypes, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';

import { Api } from '../../api';
import FillButton from '../../components/FillButton';
import SingleSelect from '../../components/SingleSelect';
import TextInputWithTitle from '../../components/TextInputWithTitle';
import { createImageUrl } from '../../util/util';

export default function Comment(props) {

    const history = useHistory();

    function listModeOnClick() {
        history.push(`/comments/${props.id}`);
    }

    const isDeleted = !!props.isDeleted;
    const isDisabledUser = !!props.user.disabled;

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [banReasons, setBanReasons] = useState([]);

    const [deleteReason, setReason] = useState('profiteering');
    const [deleteAdditionalReason, setAdditionalReason] = useState('');

    const deleteCurrentReason = banReasons.find(r => r.id === deleteReason);
    const deleteCommentIsValid = !!deleteCurrentReason?.text || deleteAdditionalReason;

    useEffect(() => {
        async function getReasons() {
            const reasons = await Api.Reports.GetBanReasons();

            setBanReasons(reasons.data);
        }
        if (!props.listMode) {
            getReasons();
        }
    }, [props]);

    return (
        <div className={classNames({
            'comment-container ': true,
            'content-panel': true,
            'comment-container-with-active-report': props.activity.reportCount,
            'comment-container-deleted': isDeleted,
            'comment-container-disabled-user': isDisabledUser,
        })}
            data-id={props.id}
            onClick={props.listMode ? listModeOnClick : null}
        >
            <div className='post-top-container'>

                <img className='post-user-image' src={createImageUrl(props.user.image)} />

                <div className='post-top-info'>

                    <div className='post-user-name'>

                        {props.user.name}

                    </div>

                    <div className='post-time'>
                        {moment(props.createTime).calendar()}
                    </div>

                    <div className='post-university'>
                        {props.user.universityName}
                    </div>

                </div>

                <div>
                    {isDeleted && <div>Comment is deleted</div>}
                    {isDisabledUser && <div>User is disabled</div>}

                    {/*!props.listMode && */!isDeleted && <FillButton
                        onClick={() => setDeleteModalOpen(true)}>
                        Delete
                    </FillButton>}
                </div>

            </div>

            <div className='comment-main'>

                <div className='comment-body'>
                    {props.comment}
                </div>

            </div>

            <Modal
                isOpen={deleteModalOpen}
                onRequestClose={() => setDeleteModalOpen(false)}
                contentLabel='Delete comment'>

                <SingleSelect
                    className='report-control'
                    name='reason'
                    label='Reason'
                    options={banReasons}
                    value={deleteReason}
                    onChange={setReason} />

                <TextInputWithTitle
                    className='report-control'
                    name='additional'
                    label='Additional detail'
                    value={deleteAdditionalReason}
                    multiline
                    onChange={setAdditionalReason}
                    controlClass='report-additional' />

                <div className='report-preview-header'>
                    The following message will be sent
                </div>

                <textarea className='report-preview' disabled value={
                    `This is an automated message to let you know the following comment has been deleted from UniSalad.

${props.comment}

${deleteCurrentReason?.text ? `This is because: ${deleteCurrentReason?.text}` : ''}

${deleteAdditionalReason ? `${deleteAdditionalReason}` : ''}`}
                />

                <FillButton onClick={deleteComment} disabled={!deleteCommentIsValid}>
                    Delete comment
                </FillButton>

                <FillButton onClick={() => setDeleteModalOpen(false)}>
                    Cancel
                </FillButton>

            </Modal>

        </div>
    );

    async function deleteComment() {
        await Api.Posts.DeleteComment(props.id, deleteReason, deleteAdditionalReason);

        window.location.reload();
    }
}
