import './RegistrationList.css';

import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { Api } from '../../api';

export default function RegistrationList(props) {

    const [registrations, setRegistrations] = useState([]);

    function loadData() {
        Api.Registrations.GetRegistrations()
            .then(result => {
                setRegistrations(result.data);
            });
    };

    useEffect(loadData, [props]);

    return (
        <div className='registration-list-container'>

            <table className='registration-list-table'>
                <thead>
                    <tr>
                        <th>Email</th>
                        <th>Code</th>
                        <th>Expiry</th>
                    </tr>
                </thead>

                <tbody>
                    {registrations.map(u => {

                        return <tr key={u.email}>

                            <td>{u.email}</td>
                            <td>{u.code}</td>
                            <td>{moment(u.expiry).format('LLL')}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    );
};
