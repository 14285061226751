import moment from "moment";

import { ApiGet, ApiPost } from "../helpers";

export function Ban(userId, params) {
  return ApiPost(`users/user/${userId}/ban`, {}, params);
}

export function Unban(userId) {
  return ApiPost(`users/user/${userId}/unban`, {});
}

export function GetProfile(userId, params) {
  return ApiGet(`users/user/${userId}`, params || {});
}

export function GetUsers(filters) {
  const queryParams = {
    limit: 100,
  };

  if (filters.noLimit) {
    delete queryParams.limit;
  }

  const keys = Object.keys(filters);

  for (let i = 0; i < keys.length; i++) {
    let value = filters[keys[i]];

    if (value === undefined) {
      continue;
    }

    if (moment.isMoment(value)) {
      value = value.valueOf();
    }
    queryParams[keys[i]] = value;
  }

  return ApiGet(`users/list`, queryParams);
}
