// import "./LocationList.css";
import React, { useEffect, useState } from "react";
import { Api } from "../../api";
import { Modal } from "@mui/material";

export default function LocationList(props) {
  const [locations, setLocations] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [createLocationForm, setCreateLocationForm] = useState({
    name: "",
  });

  function updateLocation(locationId) {
    const newName = prompt(`Enter new location name for ID ${locationId}`);

    if (!newName) return;
    Api.Locations.UpdateLocation(locationId, newName)
      .then(() => {
        loadData();
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }

  function deleteLocation(locationId) {
    Api.Locations.DeleteLocation(locationId)
      .then(() => {
        loadData();
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }

  function loadData() {
    // TODO: add pagination
    Api.Locations.GetLocations()
      .then((result) => {
        setLocations(result.data);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }

  function onCreateLocation() {
    Api.Locations.CreateLocation(createLocationForm.name)
      .then(() => {
        setIsCreateModalOpen(false);
        loadData();
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }

  useEffect(loadData, []);

  return (
    <div className="location-list-container">
      <div className="location-list-list">
        <button onClick={() => setIsCreateModalOpen(true)}>
          Create Location
        </button>

        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Location</th>
              <th style={{ textAlign: "end" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {locations.map((l, i) => (
              <tr key={i}>
                <td>{l.id}</td>
                <td>{l.location}</td>
                <td style={{ textAlign: "end" }}>
                  <button onClick={() => updateLocation(l.id)}>Edit</button>
                  <button onClick={() => deleteLocation(l.id)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        open={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
      >
        <div
          className="location-list-create"
          style={{
            backgroundColor: "white",
            textAlign: "center",
            paddingTop: "3rem",
            paddingBottom: "3rem",
          }}
        >
          <h1>Create Location</h1>

          <label>
            Name
            <input
              type="text"
              value={createLocationForm.name}
              onChange={(v) =>
                setCreateLocationForm({
                  ...createLocationForm,
                  name: v.target.value,
                })
              }
            />
          </label>

          <br />
          <br />
          <button onClick={onCreateLocation}>Create</button>
        </div>
      </Modal>
    </div>
  );
}
