import classNames from 'classnames';
import React from 'react';

export default function TextInputWithTitle(props) {

    const {
        className,
        onChange,
        name,
        label,
        multiline,
        controlClass,
        ...rest
    } = props;

    let classes = { 'text-input-container': true };
    if (className) {
        classes[className] = true;
    }

    const elementProps = {
        name: name,
        id: name,
        onChange: () => {
            const element = document.getElementById(name);
            onChange(element.value);
        },
        className: controlClass,
        ...rest,
    };

    return (
        <div className={classNames(classes)}>

            {label && <div className='text-input-label'>{label}</div>}

            {multiline
                ? <textarea {...elementProps} />
                : <input {...elementProps} />
            }

        </div>);
}
