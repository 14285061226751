import { Ban, Unban, GetProfile, GetUsers } from "./profile";
import {
  AddFcmToken,
  AuthenticateFacebook,
  AuthenticateUser,
  DeleteFcmToken,
  GetLatestSessionData,
  LogoutEverywhere,
  LogoutSession,
} from "./session";

export const Users = {
  AuthenticateUser,
  AuthenticateFacebook,
  LogoutSession,
  LogoutEverywhere,
  GetLatestSessionData,

  AddFcmToken,
  DeleteFcmToken,

  GetProfile,
  GetUsers,

  Ban,
  Unban,
};
