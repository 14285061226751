import './PostListFilter.css';

import React, { useEffect, useState } from 'react';

import { Api } from '../../api';
import Checkbox from '../../components/Checkbox';
import { ChipFilter } from '../../components/ChipFilter';
import MultipleSelect from '../../components/MultipleSelect';
import { FilterPostTypeGroupRoot, FilterPostTypeGroups } from '../../data/post-config';

let loadTimer = null;
export default function PostListFilter(props) {

    const [universityList, setUniversityList] = useState([]);
    const [hasUniversityList, setHasUniversityList] = useState(false);

    const [selectedUniversities, setSelectedUniversities] = useState([]);

    const [activeReports, setActiveReports] = useState(false);

    const [postTypes, setPostTypes] = useState([]);

    const [deletedPosts, setDeletedPosts] = useState(false);

    const [disabledUsers, setDisabledUsers] = useState(false);

    const [pinnedPostsOnly, setPinnedPostsOnly] = useState(false);

    const [pendingNotificationReview, setPendingNotificationReview] = useState(false);

    const [typeState, setTypeState] = useState([]);

    function _onPressTypeChip(option, depth) {
        const currentStateStack = typeState;

        const newStateStack = currentStateStack.slice(0, depth);

        if (option) {
            newStateStack[depth] = option.key;
        }

        const types = newStateStack.length > 0
            ? _getPostTypesFromStack(newStateStack[newStateStack.length - 1])
            : [];

        setTypeState(newStateStack);
        setPostTypes(types);
    }

    function _getPostTypesFromStack(topStackValue) {
        const topFilterGroup = FilterPostTypeGroups.find(g => g.id === topStackValue);

        if (!topFilterGroup || !topFilterGroup.options) {
            // If we don't find a group, this is a leaf node
            return [topStackValue];
        }

        const res = [];

        topFilterGroup.options.forEach(opt => {
            if (!opt.childGroup) {
                res.push(opt.key);
            }
            else {
                res.push(..._getPostTypesFromStack(opt.childGroup));
            }
        });

        return res;
    }

    useEffect(() => {
        if (!hasUniversityList) {
            Api.GetUniversities()
                .then(result => {
                    setUniversityList(result.data);
                    setHasUniversityList(true);
                });
        }
    }, [hasUniversityList]);

    useEffect(() => {
        if (loadTimer) {
            clearTimeout(loadTimer);
        }
        loadTimer = setTimeout(() => {
            props.onFilterChange({
                universities: selectedUniversities,
                activeReports: activeReports,
                deletedPosts: deletedPosts,
                disabledUsers: disabledUsers,
                types: postTypes,
                pinned: pinnedPostsOnly,
                pendingNotificationReview: pendingNotificationReview,
            })
        }, 500);
    }, [selectedUniversities, activeReports, deletedPosts, disabledUsers, postTypes, pinnedPostsOnly, pendingNotificationReview]);

    return (
        <>
            <div className='post-list-filter-container'>

                {!props.hasUniversity && <MultipleSelect
                    name='university-filter'
                    options={universityList}
                    onChange={setSelectedUniversities} />}

                <div className='post-list-filter-stacked-options'>

                    <Checkbox
                        name='pending-notification-review'
                        label='Show only posts that are pending notification review'
                        onChange={setPendingNotificationReview}
                        checked={pendingNotificationReview} />

                    <Checkbox
                        name='show-active-reports'
                        label='Show only posts with active reports'
                        onChange={setActiveReports} />

                    <Checkbox
                        name='show-deleted'
                        label='Show deleted posts'
                        onChange={setDeletedPosts} />

                    <Checkbox
                        name='show-disabled'
                        label='Show disabled (banned or deleted) users'
                        onChange={setDisabledUsers}
                        checked={disabledUsers} />

                    <Checkbox
                        name='pinned-only'
                        label='Show pinned posts only'
                        onChange={setPinnedPostsOnly}
                        checked={pinnedPostsOnly} />

                </div>

            </div>

            {_renderChipList(FilterPostTypeGroupRoot, typeState, 0)}
        </>
    );

    function _renderChipList(group, groupState, depth) {
        if (!group.options) {
            return undefined;
        }

        const childGroup = FilterPostTypeGroups.find(g => g.id === groupState[0]);

        return (<>
            <ChipFilter
                options={group.options}
                canDeselect={true}
                onPress={(item) => _onPressTypeChip(item, depth)}
                initialValue={groupState[0]}
                containerClass='post-list-chip-container'
                chipClass='post-list-chip'
                chipClassUnselected='post-list-chip-unselected'
                chipClassDeselected='post-list-chip-deselected' />

            {childGroup && _renderChipList(childGroup, groupState.slice(1), depth + 1)}
        </>);
    }
}
