export const ApiErrorCodes = {
    NONE: 0,

    AUTH_MISSING_SESSION: 1,
    AUTH_BAD_SESSION_TOKEN_FORMAT: 2,
    AUTH_INVALID_SESSION_TOKEN: 3,
    AUTH_DUPLICATE_SESSION_TOKEN: 4,

    LOGIN_INVALID_CREDENTIALS: 10,
    LOGIN_FACEBOOK_ERROR: 11,

    REGISTRATION_MISSING_EMAIL: 20,
    REGISTRATION_EMAIL_DOMAIN_NOT_RECOGNISED: 21,
    REGISTRATION_MISSING_ID: 22,
    REGISTRATION_MISSING_CODE: 23,
    REGISTRATION_INVALID_CODE: 24,
    REGISTRATION_INVALID_ID: 25,
    REGISTRATION_MISSING_PASSWORD_OR_TOKEN: 26,
    REGISTRATION_EMAIL_IN_USE: 27,
    REGISTRATION_FACEBOOK_IN_USE: 28,

    ME_NOT_FOUND: 50,
    ME_CHANGE_PASSWORDS_DO_NOT_MATCH: 51,

    MESSENGER_CREATE_CONVERSATION_NOT_ENOUGH_PARTICIPANTS: 100,
};
