import './ChipFilter.css';

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

export function ChipFilter(props) {

    const [selected, setSelected] = useState(props.initialValue
        ? props.options.find(o => o.key === props.initialValue)
        : undefined);

    useEffect(() => {

        setSelected(props.initialValue
            ? props.options.find(o => o.key === props.initialValue)
            : undefined);

    }, [props.initialValue]);

    function _onPress(option) {
        const finalOption = props.canDeselect && selected?.key === option?.key ? undefined : option;

        setSelected(finalOption);
        props.onPress(finalOption);
    }

    const listClasses = {
        'chip-list': true,
    };

    if (props.containerClass) {
        listClasses[props.containerClass] = true;
    } else {
        listClasses['chip-list-default'] = true;
    }

    return (
        <div className={classNames(listClasses)}>
            {props.options.map(option => (<Chip
                key={option.key}
                optionData={option}
                onPress={(option) => _onPress(option)}
                unselected={selected?.key !== option.key}
                deselected={selected?.key !== undefined && selected?.key !== option.key}
                hasSelected={selected?.key !== undefined}
                style={props.chipStyle}
                wrapMode={props.wrapMode}
                chipClass={props.chipClass}
                chipClassUnselected={props.chipClassUnselected}
                chipClassDeselected={props.chipClassDeselected} />))}
        </div>)
}

function Chip(props) {

    const chipClasses = {
        chip: true,
    }

    if (props.optionData.icon) {
        chipClasses['chip-with-icon'] = true;
    }

    if (props.deselected) {

        if (props.chipClassDeselected) {
            chipClasses[props.chipClassDeselected] = true;
        } else {
            chipClasses['chip-deselected-default'] = true;
        }
    } else if (props.unselected) {

        if (props.chipClassUnselected) {
            chipClasses[props.chipClassUnselected] = true;
        } else {
            chipClasses['chip-unselected-default'] = true;
        }
    } else {

        if (props.chipClass) {
            chipClasses[props.chipClass] = true;
        } else {
            chipClasses['chip-default'] = true;
        }
    }

    return (
        <div className='chip-container'>

            <div
                className={classNames(chipClasses)}
                onClick={() => props.onPress(props.optionData)}>

                {props.optionData.icon &&
                    <img
                        src={props.optionData.icon}
                        style={{
                            width: props.optionData.iconSize || '30px',
                            height: props.optionData.iconSize || '30px',
                        }} />
                }

                {!props.optionData.icon &&
                    <p>{props.optionData.label}</p>
                }

            </div>

        </div>
    );
}
