import { Formik } from 'formik';
import React, { useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import FillButton from '../components/FillButton';
import TextInputWithTitle from '../components/TextInputWithTitle';
import Auth from '../util/Auth';

export default function LoginPage() {

    const _validator = yup.object().shape({
        email: yup
            .string()
            .required('Email address is required')
            .email('Must be a valid email address'),

        password: yup
            .string()
            .required('Password is required')
    });

    let [isSigningIn, setIsSigningIn] = useState(false);

    let history = useHistory();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/" } };

    let _handleSubmit = 'eee';

    function _onSubmitLogon(values) {

        if (isSigningIn) {
            // We are already mid-sign in
            return;
        }

        setIsSigningIn(true);

        Auth.authenticate(values.email, values.password, () => {
            setIsSigningIn(false);
            history.replace(from);
        });
    }

    if (Auth.isAuthenticated()) {
        return <Redirect
            to={{
                pathname: '/',
            }}
        />
    }

    return (
        <div>
            <p>You must log in to view the page at {from.pathname}</p>

            <Formik
                initialValues={{ email: '', password: '' }}
                initialErrors={{ email: 'Required', password: 'Required' }}
                validationSchema={_validator}
                onSubmit={values => _onSubmitLogon(values)}>
                {(formik) => {

                    _handleSubmit = formik.handleSubmit;

                    return <>

                        <TextInputWithTitle
                            title='University email address'
                            name='email'
                            onChange={v => formik.setFieldValue('email', v)}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            error={formik.touched.email || formik.submitCount ? formik.errors.email : undefined} />

                        <TextInputWithTitle
                            title='Password'
                            name='password'
                            type='password'
                            onChange={v => formik.setFieldValue('password', v)}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            error={formik.touched.password || formik.submitCount ? formik.errors.password : undefined} />

                        <FillButton
                            onClick={_handleSubmit}
                            style={{ width: 200, alignSelf: 'center' }}
                            disabled={!formik.isValid || isSigningIn}>

                            Log In

                        </FillButton>

                    </>
                }}
            </Formik>

        </div>
    );
}