import { ApiDelete, ApiGet, ApiPost, ApiPut } from "./helpers";

/**
 * Get locations
 *
 * @param {number} offset pagination offset
 * @param {number} limit pagination limit
 * @returns paginated locations
 */
function GetLocations(offset, limit) {
  return ApiGet("locations", { offset: offset ?? '', limit: limit ?? '' });
}

/**
 * Create a location
 *
 * @param {string} name location
 * @returns created location
 */
function CreateLocation(name) {
  return ApiPost("locations", {}, { name });
}

/**
 * Update a location
 *
 * @param {number} id
 * @param {string} name
 * @returns updated location
 */
function UpdateLocation(id, name) {
  return ApiPut(`locations/${id}`, {}, { name });
}

/**
 * Delete a location
 *
 * @param {number} id
 * @returns deleted location id
 */
function DeleteLocation(id) {
  return ApiDelete(`locations/${id}`);
}

export { GetLocations, CreateLocation, UpdateLocation, DeleteLocation };
