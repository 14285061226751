import { ApiGet, ApiPost } from './helpers';

function GetAutocompleteData(field, state, universityGroupId, search) {

    let params = {};
    if (field) { params.field = field; }
    if (state) { params.state = state; }
    if (universityGroupId) { params.universityGroupId = universityGroupId; }
    if (search) { params.search = search; }

    return ApiGet('data/autocomplete', params);
}

function AddAutocompleteData(field, text) {

    return ApiPost('data/autocomplete', { field }, { text });
}

function SetAutocompleteEntryStatus(id, status) {

    return ApiPost('data/autocomplete/setStatus', { id, status });
}

export const Data = {
    GetAutocompleteData,
    AddAutocompleteData,
    SetAutocompleteEntryStatus,
}
