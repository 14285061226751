import './UserListFilter.css';

import React, { useEffect, useState } from 'react';

import { Api } from '../../api';
import Checkbox from '../../components/Checkbox';
import MultipleSelect from '../../components/MultipleSelect';
import TextInputWithTitle from '../../components/TextInputWithTitle';

let loadTimer = null;
export default function UserListFilter(props) {

    const [universityList, setUniversityList] = useState([]);
    const [hasUniversityList, setHasUniversityList] = useState(false);

    const [selectedUniversities, setSelectedUniversities] = useState([]);

    const [activeReports, setActiveReports] = useState(false);
    const [showDisabledUsers, setShowDisabledUsers] = useState(false);
    const [search, setSearch] = useState('');

    useEffect(() => {
        if (!hasUniversityList) {
            Api.GetUniversities()
                .then(result => {
                    setUniversityList(result.data);
                    setHasUniversityList(true);
                });
        }
    }, [hasUniversityList]);

    useEffect(() => {
        if (loadTimer) {
            clearTimeout(loadTimer);
        }
        loadTimer = setTimeout(() => {
            props.onFilterChange({
                universities: selectedUniversities,
                activeReports: activeReports,
                search: search,
                showDisabledUsers: showDisabledUsers,
            })
        }, 500);
    }, [selectedUniversities, activeReports, search, showDisabledUsers,]);

    return (

        <div className='user-list-filter-container'>

            <MultipleSelect
                name='university-filter'
                options={universityList}
                onChange={setSelectedUniversities} />

            <div className='user-list-filter-stacked-options'>

                <Checkbox
                    name='show-active-reports'
                    label='Show only users with active reports'
                    onChange={setActiveReports} />

                <Checkbox
                    name='show-disabled-users'
                    label='Show disabled users'
                    onChange={setShowDisabledUsers} />

            </div>

            <div className='user-list-filter-stacked-options'>
                <TextInputWithTitle
                    label='Search'
                    name='search'
                    onChange={s => setSearch(s)}
                    value={search} />
            </div>
        </div>
    );
}
