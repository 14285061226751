import "./UserDetail.css";

import { Tab, Tabs } from "@mui/material";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";

import { Api } from "../../api";
import TabPanel from "../../components/TabPanel";

import CommentList from "../comments/CommentList";
import ReportListAll from "../reports/ReportListAll";
import PostList from "../posts/PostList";
import User from "./User";
import Conversation from "./Conversation";
import Conversations from "./Conversations";
import Checkbox from "../../components/Checkbox";

export default function UserDetail(props) {
  const history = useHistory();
  const search = useLocation().search;
  const urlParams = new URLSearchParams(window.location.search);

  let { userId } = useParams();

  let [currentTab, setCurrentTab] = useState(1);
  const [activeReports, setActiveReports] = useState(true);

  let [user, setUser] = useState(undefined);
  let [reports, setReports] = useState([]);
  let [showOnlyActive, setShowOnlyActive] = useState(true);

  let [showConversationWithUser, setShowConversationWithUser] = useState(null);

  async function loadReports() {
    const reportResult = await Api.Reports.GetReports("user", userId, {
      showOnlyActive,
    });

    setReports(reportResult.data);
  }

  function onTabChange(event, index) {
    history.push(`/users/${userId}?tab=${index}`);
  }

  useEffect(() => {
    async function fetchData() {
      const userResult = await Api.Users.GetProfile(userId, {
        showDisabledUsers: true,
      });
      setUser(userResult.data);
      await loadReports();
    }
    fetchData();
  }, [userId]);

  useEffect(() => {
    loadReports();
  }, [showOnlyActive]);

  useEffect(() => {
    let tab = urlParams.get("tab");
    if (!tab) {
      tab = 1;
    }
    tab = parseInt(tab);

    setCurrentTab(tab);

    let conversationWith = urlParams.get("conversationWith");
    if (conversationWith !== null) {
      conversationWith = parseInt(conversationWith);
    }
    setShowConversationWithUser(conversationWith);
  }, [search]);

  if (!user) {
    return <div>Loading</div>;
  }

  return (
    <div className="user-detail-container">
      <User {...user} listMode={false} />

      <Tabs value={currentTab} onChange={onTabChange}>
        <Tab label="Reports against user" />
        <Tab label="User posts" />
        <Tab label="User comments" />
        <Tab label="User conversations" />
      </Tabs>

      <TabPanel value={currentTab} index={0}>
        <div
          className={classNames({
            "user-report-container": true,
            "content-panel": true,
          })}
        >
        <Checkbox
          name="show-active-reports"
          label="Show only active reports"
          onChange={setActiveReports}
          checked={activeReports}
        />
          <ReportListAll userId={userId} showActions={true} onlyActive={activeReports} />
        </div>
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <PostList filter={{ userIds: [userId] }} hasUniversity={true} />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <CommentList filter={{ userIds: [userId] }} />
      </TabPanel>
      <TabPanel value={currentTab} index={3}>
        {renderConversationPanel()}
      </TabPanel>
    </div>
  );

  function renderConversationPanel() {
    return showConversationWithUser ? (
      <Conversation sourceUser={userId} withUser={showConversationWithUser} />
    ) : (
      <Conversations sourceUser={userId} />
    );
  }
}
