import React, { useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";

import { Api } from "../../api";
import FillButton from "../../components/FillButton";

export default function ReportListShared(props) {
  const history = useHistory();

  const [reports, setReports] = useState([]);
  const [ignoreModalOpen, setIgnoreModalOpen] = useState(false);

  const [selectedReport, setSelectedReport] = useState(null);

  function loadData() {
    let filterObj = {};
    if (props.userId) {
      filterObj.userId = props.userId;
    }
    const finalFilter = Object.assign(
      {
        showOnlyActive: props.onlyActive,
      },
      filterObj
    );

    Api.Reports.GetReports(props.type, undefined, finalFilter).then(
      (result) => {
        setReports(result.data);
      }
    );
  }

  useEffect(loadData, [props.onlyActive, props.type]);

  // TODO: Sort this out
  function click(data, conversationMode) {
    if (data.type === "post") {
      history.push(`/posts/${data.postId}`);
    } else if (data.type === "user") {
      history.push(
        `/users/${data.reportedUser.id}?tab=${
          !conversationMode ? "0" : `3&conversationWith=${data.reporterUser.id}`
        }`
      );
    } else if (data.type === "comment") {
      history.push(`/comments/${data.commentId}`);
    }
  }

  if (reports.length === 0) {
    return <div key="content">No reports to show</div>;
  }

  return [
    <div key="content">
      <table>
        <thead>
          <tr>
            {props.showType ? <th>Reported</th> : null}
            <th>Date</th>
            <th>Reported By User</th>
            <th>Reason</th>
            <th>Detail</th>
            <th>State</th>
            <th>Reported User</th>
            {props.showActions ? <th>Actions</th> : null}
          </tr>
        </thead>

        <tbody>
          {reports.map((u) => {
            const rowClasses = {
              "report-row": true,
            };
            if (u.active) {
              rowClasses["report-row-active"] = true;
            }

            return [
              <tr key={u.id + ""} className={classNames(rowClasses)}>
                {props.showType ? <td>{u.type}</td> : null}
                <td>{moment(u.reportTime).format("L LT")}</td>
                <td>{`${u.reporterUser.firstName} ${
                  u.reporterUser.lastName ? u.reporterUser.lastName : ""
                } (${u.reporterUser.email})`}</td>
                <td>{u.reason}</td>
                <td>{u.detail}</td>
                <td>{u.state}</td>
                <td>{`${u.reportedUser.firstName} ${
                  u.reportedUser.lastName ? u.reportedUser.lastName : ""
                } (${u.reportedUser.email})`}</td>
                {props.showActions ? renderActions(u) : null}
              </tr>,
            ];
          })}
        </tbody>
      </table>
    </div>,
    <Modal
      key="modal"
      isOpen={ignoreModalOpen}
      onRequestClose={() => setIgnoreModalOpen(false)}
      contentLabel="Ignore report"
    >
      {!ignoreModalOpen ? null : (
        <div>
          <h2>Do you want to ignore this report?</h2>
          <table>
            <tr>
              <td>Reported by</td>
              <td>{`${selectedReport.reportedUser.firstName} ${
                selectedReport.reportedUser.lastName
                  ? selectedReport.reportedUser.lastName
                  : ""
              } (${selectedReport.reportedUser.email})`}</td>
            </tr>
            <tr>
              <td>Reason</td>
              <td>{selectedReport.reason}</td>
            </tr>
            <tr>
              <td>Detail</td>
              <td>{selectedReport.detail}</td>
            </tr>
          </table>

          <FillButton onClick={setReportIgnored}>
            Ignore this report only
          </FillButton>
          <br />
          <FillButton onClick={setAllSameTypeReportsIgnored}>
            Ignore all reports for the same {selectedReport.type}
          </FillButton>
          <br />
          <FillButton onClick={setAllUserReportsIgnored}>
            Ignore all reports for this user, and their posts and comments
          </FillButton>
          <br />

          <FillButton onClick={() => setIgnoreModalOpen(false)}>
            Cancel
          </FillButton>
        </div>
      )}
    </Modal>,
  ];

  function renderActions(report) {
    return (
      <td>
        <FillButton onClick={() => click(report)}>View</FillButton>
        {report.type === "user" ? (
          <FillButton onClick={() => click(report, true)}>
            View conversation between these users
          </FillButton>
        ) : null}
        {report.active ? (
          <FillButton onClick={() => openIgnoreModal(report)}>
            Ignore
          </FillButton>
        ) : null}
      </td>
    );
  }

  function openIgnoreModal(report) {
    setSelectedReport(report);
    setIgnoreModalOpen(true);
  }

  async function setReportIgnored() {
    await Api.Reports.CloseReport(selectedReport.id);

    window.location.reload();
  }

  async function setAllSameTypeReportsIgnored() {
    let id = null;
    switch (selectedReport.type) {
      default:
        throw Error("Unknown type " + selectedReport.type);

      case "user":
        id = selectedReport.reportedUser.id;
        break;
      case "post":
        id = selectedReport.postId;
        break;
      case "comment":
        id = selectedReport.commentId;
        break;
    }
    await Api.Reports.CloseRelatedReports(id, selectedReport.type);

    window.location.reload();
  }

  async function setAllUserReportsIgnored() {
    await Api.Reports.CloseRelatedReports(
      selectedReport.reportedUser.id,
      "all"
    );

    window.location.reload();
  }
}
