import "./User.css";

import classNames from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useHistory } from "react-router-dom";

import { Api } from "../../api";
import Checkbox from "../../components/Checkbox";
import DateInputWithTitle from "../../components/DateInput";
import FillButton from "../../components/FillButton";
import SingleSelect from "../../components/SingleSelect";
import TextInputWithTitle from "../../components/TextInputWithTitle";
import { createImageUrl } from "../../util/util";

export default function User(props) {
  const history = useHistory();

  function listModeOnClick() {
    history.push(`/users/${props.id}`);
  }

  var defaultExpiry = moment().add(1, "M");

  const [banModalOpen, setBanModalOpen] = useState(false);
  const [unbanModalOpen, setUnbanModalOpen] = useState(false);

  const [autoExpireBan, setAutoExpireBan] = useState(true);

  const [banExpiryDate, setBanExpiryDate] = useState(
    defaultExpiry.format("YYYY-MM-DD")
  );

  const [reason, setReason] = useState("profiteering");
  const [additionalReason, setAdditionalReason] = useState("");

  const [banReasons, setBanReasons] = useState([]);

  const isDisabled = !!props.disabled;

  const currentReason = banReasons.find((r) => r.id === reason);
  const isValid = !!currentReason?.text || additionalReason;

  useEffect(() => {
    async function getReasons() {
      const reasons = await Api.Reports.GetBanReasons();

      setBanReasons(reasons.data);
    }
    if (!props.listMode) {
      getReasons();
    }
  }, [props]);

  return (
    <div
      className={classNames({
        "user-container ": true,
        "content-panel": true,
        "user-container-with-active-report": props.reportCount,
        "user-container-disabled": props.disabled,
      })}
      data-id={props.id}
      onClick={props.listMode ? listModeOnClick : null}
    >
      <div className="user-top-container">
        <img className="user-image" src={createImageUrl(props.image)} />

        <div className="user-top-info">
          <div className="user-name">{props.fullName}</div>

          <div className="user-university">{props.email}</div>

          <div className="user-university">{props.university.name}</div>
        </div>

        <div className="user-top-buttons">
          {!isDisabled && !props.listMode && !!banReasons.length && (
            <FillButton onClick={() => setBanModalOpen(true)}>
              Ban user
            </FillButton>
          )}
          {isDisabled && (
            <>
              <p style={{ margin: "0px" }}>User is disabled</p>
              <FillButton onClick={() => setUnbanModalOpen(true)}>
                Unban user
              </FillButton>
            </>
          )}
        </div>
      </div>

      <Modal
        isOpen={banModalOpen}
        onRequestClose={() => setBanModalOpen(false)}
        contentLabel="Ban user"
      >
        <SingleSelect
          className="report-control"
          name="reason"
          label="Reason for ban"
          options={banReasons}
          value={reason}
          onChange={setReason}
        />

        <Checkbox
          className="report-control"
          name="auto-expire"
          label="Auto-expire ban"
          checked={autoExpireBan}
          onChange={setAutoExpireBan}
        />

        {autoExpireBan && (
          <DateInputWithTitle
            className="report-control"
            name="expiry"
            label="Ban expiry date"
            value={banExpiryDate.toString()}
            onChange={setBanExpiryDate}
          />
        )}

        <TextInputWithTitle
          className="report-control"
          name="additional"
          label="Additional reason for ban"
          value={additionalReason}
          multiline
          onChange={setAdditionalReason}
          controlClass="report-additional"
        />

        <div className="report-preview-header">
          The following email will be sent
        </div>

        <textarea
          className="report-preview"
          disabled
          value={`Dear ${props.fullName}

This is an automated message to let you know you have been banned from UniSalad ${
            autoExpireBan ? `until ${banExpiryDate}` : "permanently"
          }.

${currentReason?.text ? `This is because: ${currentReason?.text}` : ""}

${additionalReason ? `${additionalReason}` : ""}

If you believe this was an error, please contact support@unisalad.com

Thanks,

The UniSalad Team`}
        />

        <FillButton onClick={activateBan} disabled={!isValid}>
          Activate ban
        </FillButton>

        <FillButton onClick={() => setBanModalOpen(false)}>Cancel</FillButton>
      </Modal>

      {/* unban modal */}
      <Modal
        isOpen={unbanModalOpen}
        onRequestClose={() => setUnbanModalOpen(false)}
        contentLabel="Unban user"
      >
        <div className="report-preview-header">
          Do you want to unban this user?
        </div>

        <div>Previous ban reason(s)</div>
        <textarea
          className="report-preview"
          disabled
          value={props.disabledReason ?? ""}
        />

        <FillButton onClick={deactivateBan} disabled={!isValid}>
          Deactivate ban
        </FillButton>

        <FillButton onClick={() => setUnbanModalOpen(false)}>Cancel</FillButton>
      </Modal>
    </div>
  );

  async function activateBan() {
    await Api.Users.Ban(props.id, {
      reason,
      autoExpireBan,
      banExpiryDate,
      additionalReason,
    });

    window.location.reload();
  }

  async function deactivateBan() {
    await Api.Users.Unban(props.id);
    window.location.reload();
  }
}
