import './CommentDetail.css';

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Api } from '../../api';
import Checkbox from '../../components/Checkbox';
import Comment from './Comment';

export default function CommentDetail(props) {

    let { commentId } = useParams();

    let [post, setPost] = useState(undefined);
    let [reports, setReports] = useState([]);
    let [showOnlyActive, setShowOnlyActive] = useState(true);

    async function loadReports() {

        const reportResult = await Api.Reports.GetReports(
            'comment',
            commentId,
            { showOnlyActive });

        setReports(reportResult.data);
    };

    useEffect(() => {
        async function fetchData() {
            const commentResult = await Api.Posts.GetComment(commentId)
            setPost(commentResult.data);

            await loadReports();
        }
        fetchData();

    }, [commentId]);

    useEffect(() => {
        loadReports();
    }, [showOnlyActive]);

    if (!post) {
        return <div>Loading</div>
    }

    return (
        <div className='comment-detail-container'>

            <Comment {...post} listMode={false} />

            {renderReports(props)}

        </div>
    );

    function renderReports(props) {
        if (!reports.length) {
            return null;
        }

        return (
            <div className={classNames({
                'comment-detail-report-list': true,
                'content-panel': true
            })}>

                <h3>Reports against this post</h3>

                <Checkbox
                    name='show-active-reports'
                    label='Show only posts with active reports'
                    checked={showOnlyActive}
                    onChange={setShowOnlyActive} />

                <table>
                    <thead>
                        <tr>

                            <td>User</td>
                            <td>Reason</td>
                            <td>Detail</td>
                            <td>Status</td>

                        </tr>
                    </thead>

                    <tbody>

                        {reports.map(r =>
                            <tr key={r.id}>

                                <td>{`${r.firstName} ${r.lastName ? r.lastName : ''} (${r.email})`}</td>
                                <td>{r.reason}</td>
                                <td>{r.detail}</td>
                                <td>{r.state}</td>

                            </tr>)
                        }

                    </tbody>
                </table>

            </div>
        );
    }
};
