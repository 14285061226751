import * as moment from "moment";

import { ApiGet } from "./helpers";

function GetConversations(forUser, params) {
  const queryParams = { forUser };

  if (params?.after) {
    queryParams.after = params.after.valueOf();
  }

  if (params?.before) {
    queryParams.before = params.before.valueOf();
  }

  return ApiGet("messenger/conversations", queryParams);
}

function GetMessages(conversationId, forUser, params) {
  const queryParams = { forUser };

  if (params?.allUnread) {
    queryParams.allUnread = true;
    queryParams.createdBefore = params.allUnread;
  } else {
    if (params?.updatedAfter) {
      queryParams.updatedAfter = params.updatedAfter.valueOf();
    }

    if (params?.createdBefore) {
      queryParams.createdBefore = params.createdBefore.valueOf();
    }
  }

  queryParams.format = 2;

  return ApiGet(
    `messenger/conversations/${conversationId}/messages`,
    queryParams
  );
}

function GetConversationSummary(forUser) {
  return ApiGet(`messenger/conversationSummary`, { forUser });
}

export const Messages = {
  GetConversations,
  GetConversationSummary,
  GetMessages,
};
