import { Api, ApiErrorCodes } from '../api';

const Auth = {
    isAuthenticated(value) {
        if (value === undefined) {
            return localStorage.getItem('isauthenticated') === 'true';
        }
        localStorage.setItem('isauthenticated', value.toString());
    },
    session(value) {
        if (value === undefined) {
            const d = localStorage.getItem('session');
            if (d) {
                return JSON.parse(d);
            }
            return null;
        }
        if (value === null) {
            localStorage.removeItem('session');
        }
        localStorage.setItem('session', JSON.stringify(value));
    },
    authenticate(email, password, cb) {

        Api.Users.AuthenticateUser(email, password)
            .then(async result => {

                if (result.code === ApiErrorCodes.NONE) {
                    this.session(result.data);
                    this.isAuthenticated(true);
                    cb();
                }
                else if (result.code === ApiErrorCodes.LOGIN_INVALID_CREDENTIALS) {
                    //Alert.alert('Invalid credentials', 'We were unable to sign you in with the credentials provided');
                }
                else {
                    //Alert.alert('Unknown error', 'Code: ' + result.code);
                }
            });
    },
    logout(cb) {
        console.warn('TODO: should sign out')
    },
    logoutCleanup(cb) {
        this.session(null);
        this.isAuthenticated(false);
        window.location.href = '/login';
        cb();
    },
};

export default Auth;
