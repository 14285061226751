import { ApiGet, ApiPut } from "./helpers";

export function GetUniversities() {
  return ApiGet("universities");
}

/**
 * Update a university's location
 *
 * @param {number} id university id
 * @param {number} locationId
 * @param {string} locationName
 * @returns updated university
 */
export function UpdateUniversityLocation(id, locationId, locationName) {
  return ApiPut(`universities/${id}`, {}, { locationId, locationName });
}
