import { ApiDelete, ApiGet, ApiPost, ApiPostPublic } from '../helpers';

export function AuthenticateUser(email, password) {
    return ApiPostPublic('session/authenticate', {}, { email: email, password: password });
}

export function AuthenticateFacebook(token) {
    return ApiPostPublic('session/authenticate', {}, { facebook: token });
}

export function LogoutSession() {
    return ApiPost('session/logOut', {}, {})
}

export function LogoutEverywhere() {
    return ApiPost('session/logOutAll', {}, {})
}

export function AddFcmToken(token) {
    return ApiPost('session/fcmToken', {}, { token: token });
}

export function DeleteFcmToken(token) {
    return ApiDelete(`session/fcmToken/${token}`, {});
}

export function GetLatestSessionData() {
    return ApiGet(`session/data`, {});
}
