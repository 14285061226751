import './LoggedInRoot.css';

import React from 'react';
import Modal from 'react-modal';
import { Link, Route } from 'react-router-dom';

import AutoCompleteList from './autocomplete/AutoCompleteList';
import CommentDetail from './comments/CommentDetail';
import CommentList from './comments/CommentList';
import PostDetail from './posts/PostDetail';
import PostList from './posts/PostList';
import RegistrationList from './registrations/RegistrationList';
import ReportList from './reports/ReportList';
import UserDetail from './users/UserDetail';
import UserList from './users/UserList';
import LocationList from './locations/LocationList';
import UniversityList from './universities/UniversityList';

Modal.setAppElement('#root');

export default function LoggedInRoot() {

    return (
        <div className='body-container'>
            <div className='top-bar-container'>
                <div className='top-bar-item'>
                    UniSalad
                </div>

                <Link className='top-bar-item' to='/posts'>Posts</Link>

                <Link className='top-bar-item' to='/users'>Users</Link>
                
                <Link className='top-bar-item' to='/locations'>Locations</Link>

                <Link className='top-bar-item' to='/universities'>Universities</Link>

                <Link className='top-bar-item' to='/reports'>Reports</Link>

                <Link className='top-bar-item' to='/registrations'>Registrations</Link>

                <Link className='top-bar-item' to='/autocompletes'>Autocompletes</Link>

                <Link className='top-bar-item' to='/comments'>Comments</Link>
            </div>

            <div className='main-container'>

                <div className='content-container'>

                    <Route exact path='/'>
                        <PostList />
                    </Route>
                    <Route exact path='/posts'>
                        <PostList />
                    </Route>
                    <Route path='/posts/:postId'>
                        <PostDetail />
                    </Route>
                    <Route exact path='/comments'>
                        <CommentList />
                    </Route>
                    <Route path='/comments/:commentId'>
                        <CommentDetail />
                    </Route>

                    <Route exact path='/users'>
                        <UserList />
                    </Route>
                    <Route path='/users/:userId'>
                        <UserDetail />
                    </Route>

                    <Route exact path='/locations'>
                        <LocationList />
                    </Route>

                    <Route exact path='/universities'>
                        <UniversityList />
                    </Route>

                    <Route exact path='/reports'>
                        <ReportList />
                    </Route>

                    <Route exact path='/registrations'>
                        <RegistrationList />
                    </Route>

                    <Route exact path='/autocompletes'>
                        <AutoCompleteList />
                    </Route>

                </div>
            </div>
        </div>);
}
