import axios from "axios";

import Auth from "../util/Auth";

const API_ENDPOINT = "https://apiv2.unisalad.com/v2/";

const UNAUTHORIZED = 401;

export function ApiUrl(path, query) {
  let url = `${API_ENDPOINT}${path}`;
  if (query) {
    let parts = [];
    for (var key in query) {
      parts.push(`${key}=${query[key]}`);
    }

    if (parts.length > 0) {
      url += `?${parts.join("&")}`;
    }
  }

  return url;
}

async function ApiMethod(method, path, pub, query, body, isFormData = false) {
  const headers = {
    Accept: "application/json",
    "Us-Client": "admin-console:1.0.0",
    //TODO: REMOVE THIS
    "Access-Control-Allow-Origin": "*",
  };

  if (!isFormData) {
    headers["Content-Type"] = "application/json";
  }

  if (!pub) {
    const session = Auth.session();
    headers.Authorization = `Bearer: ${session?.token}`;
  }
  const req = {
    url: ApiUrl(path, query),
    method: method,
    headers: headers,
  };

  if (body) {
    if (isFormData) {
      req.data = body;
    } else {
      req.data = JSON.stringify(body);
    }
  }

  return axios.request(req).then(
    (response) => {
      if (response.status === 200) {
        return response.data;
      }

      throw "HTTP error " + response.status;
    },
    (error) => {
      if (error.response && error.response.status === UNAUTHORIZED) {
        Auth.logoutCleanup(() => {
          window.alert(
            "Logged Out",
            "You have been signed out. Please sign in again",
            [{ text: "OK" }]
          );
        });

        throw UNAUTHORIZED;
      }

      throw error;
    }
  );
}

export function ApiGetPublic(path, query) {
  return ApiMethod("GET", path, true, query);
}

export function ApiPostPublic(path, query, body) {
  return ApiMethod("POST", path, true, query, body);
}

export function ApiGet(path, query) {
  return ApiMethod("GET", path, false, query);
}

export function ApiPut(path, query, body) {
  return ApiMethod("PUT", path, false, query, body);
}

export function ApiPost(path, query, body) {
  return ApiMethod("POST", path, false, query, body);
}

export function ApiPostForm(path, query, body) {
  return ApiMethod("POST", path, false, query, body, true);
}

export function ApiDelete(path, query, body) {
  return ApiMethod("DELETE", path, false, query, body);
}

export function swallowUnauthorized(error) {
  if (error === 401) {
    console.log("Gracefully handling error 401");
    return;
  }

  throw error;
}

export function GetImageInfoFromImage(image) {
  let filename = image.filename;
  if (!filename) {
    let split = image.path.split("\\");
    filename = split[split.length - 1];
    split = filename.split("/");
    filename = split[split.length - 1];
  }

  return {
    name: filename,
    uri: image.path,
    type: image.mime,
  };
}
