import './Post.css';

import { AccessTime, ArrowForward, LocationOn, Payment } from '@mui/icons-material';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ImageScroller from 'react-image-scroller';
import Modal from 'react-modal';
import { Link, useHistory } from 'react-router-dom';

import { Api } from '../../api';
import FillButton from '../../components/FillButton';
import SingleSelect from '../../components/SingleSelect';
import TextInputWithTitle from '../../components/TextInputWithTitle';
import postBuy from '../../images/icons/post-buy.png';
import postFound from '../../images/icons/post-found.png';
import postHousingAvailable from '../../images/icons/post-housing-available.png';
import postHousingLooking from '../../images/icons/post-housing-looking.png';
import postLost from '../../images/icons/post-lost.png';
import postSell from '../../images/icons/post-sell.png';
import postTravelBus from '../../images/icons/post-travel-bus.png';
import postTravelLift from '../../images/icons/post-travel-car.png';
import postTravelOther from '../../images/icons/post-travel-other.png';
import postTravelTrain from '../../images/icons/post-travel-train.png';
import { createImageUrl } from '../../util/util';

export default function Post(props) {

    const history = useHistory();

    function listModeOnClick() {
        history.push(`/posts/${props.id}`);
    }

    const [pinModalOpen, setPinModalOpen] = useState(false);

    const isDeleted = !props.visible || !props.isListed;
    const isDisabledUser = !!props.user.disabled;
    const isPinned = !!props.isPinned;

    const [notificationModalOpen, setNotificationModalOpen] = useState(false);
    const [notificationDenyReason, setNotificationDenyReason] = useState('');

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [banReasons, setBanReasons] = useState([]);

    const [deleteReason, setReason] = useState('profiteering');
    const [deleteAdditionalReason, setAdditionalReason] = useState('');

    const deleteCurrentReason = banReasons.find(r => r.id === deleteReason);
    const deletePostIsValid = !!deleteCurrentReason?.text || deleteAdditionalReason;

    useEffect(() => {
        async function getReasons() {
            const reasons = await Api.Reports.GetBanReasons();

            setBanReasons(reasons.data);
        }
        if (!props.listMode) {
            getReasons();
        }
    }, [props]);

    let hasTitle = !!props.title || props.type.startsWith('housing-') || props.type.startsWith('travel-');

    return (
        <div className={classNames({
            'post-container ': true,
            'content-panel': true,
            'post-container-with-active-report': props.activity.reportCount,
            'post-container-deleted': isDeleted,
            'post-container-disabled-user': isDisabledUser,
            'post-container-pending-notification-review': props.notifyGlobal && props.notifyGlobalState === null,
        })}
            data-id={props.id}
            onClick={props.listMode ? listModeOnClick : null}>
            <div className='post-top-container'>

                <img className='post-user-image' src={createImageUrl(props.user.image)} />

                <div className='post-top-info'>

                    <div className='post-user-name'>
                        <Link to={`/users/${props.user.id}`}>
                            {props.user.name}
                        </Link>
                    </div>

                    <div className='post-time'>
                        {moment(props.createTime).calendar()}
                    </div>

                    <div className='post-university'>
                        {props.user.universityName}
                    </div>

                </div>

                <div>
                    {isDeleted && <div>Post is deleted</div>}
                    {isDisabledUser && <div>User is disabled</div>}
                    {isPinned && props.listMode && <div>Post is pinned</div>}

                    {!props.listMode && <FillButton
                        onClick={() => setPinModalOpen(true)}>
                        {isPinned ? 'Unpin' : 'Pin'}
                    </FillButton>}

                    {!props.listMode && !isDeleted && <FillButton
                        onClick={() => setDeleteModalOpen(true)}>
                        Delete
                    </FillButton>}

                    {!props.listMode && props.notifyGlobal && props.notifyGlobalState === null && <FillButton
                        onClick={() => setNotificationModalOpen(true)}>
                        Review notification
                    </FillButton>}
                </div>

            </div>

            <div className='post-main'>

                {props.listMode && props.posterImage &&
                    <img className='post-image-scroller' src={createImageUrl(props.posterImage)} />
                }

                {!props.listMode && props.images.length > 0 &&
                    <ImageScroller className='post-image-scroller'>
                        {props.images.map(i => <img key={i.key} src={createImageUrl(i)} />)}
                    </ImageScroller>}

                {hasTitle &&
                    <div className='post-title-container'>
                        <img className='post-title-image' src={getTitleImage()} />
                        <div className='post-title-pre'>
                            {getPreTitle()}
                        </div>
                        <div className='post-title'>
                            {createTitle()}
                        </div>
                    </div>
                }

                {renderTypeContent(props)}

                <div className='post-body'>
                    {props.body}
                </div>

            </div>

            <Modal
                isOpen={pinModalOpen}
                onRequestClose={() => setPinModalOpen(false)}
                contentLabel='Pin/Unpin post'>

                <div className='post-pin-dialog'>
                    <p>Are you sure you want to {isPinned ? 'Unpin' : 'Pin'} this post?</p>

                    {!isPinned && <p>Pinning this post will unpin any other posts for this university</p>}

                    <FillButton onClick={applyPin}>
                        {isPinned ? 'Unpin' : 'Pin'} post
                    </FillButton>

                    <FillButton onClick={() => setPinModalOpen(false)}>
                        Cancel
                    </FillButton>
                </div>

            </Modal>

            <Modal
                isOpen={deleteModalOpen}
                onRequestClose={() => setDeleteModalOpen(false)}
                contentLabel='Delete post'>

                <SingleSelect
                    className='report-control'
                    name='reason'
                    label='Reason'
                    options={banReasons}
                    value={deleteReason}
                    onChange={setReason} />

                <TextInputWithTitle
                    className='report-control'
                    name='additional'
                    label='Additional detail'
                    value={deleteAdditionalReason}
                    multiline
                    onChange={setAdditionalReason}
                    controlClass='report-additional' />

                <div className='report-preview-header'>
                    The following message will be sent
                </div>

                <textarea className='report-preview' disabled value={
                    `This is an automated message to let you know the following post has been deleted from UniSalad.

${getPreTitle() ? getPreTitle() : 'Post'} - ${props.title ? createTitle() : props.body}

${deleteCurrentReason?.text ? `This is because: ${deleteCurrentReason?.text}` : ''}

${deleteAdditionalReason ? `${deleteAdditionalReason}` : ''}`}
                />

                <FillButton onClick={deletePost} disabled={!deletePostIsValid}>
                    Delete post
                </FillButton>

                <FillButton onClick={() => setDeleteModalOpen(false)}>
                    Cancel
                </FillButton>

            </Modal>

            <Modal
                isOpen={notificationModalOpen}
                onRequestClose={() => setNotificationModalOpen(false)}
                contentLabel='Send notification?'>

                <TextInputWithTitle
                    className='report-control'
                    name='notification-reason'
                    label='Reason for notification denial'
                    value={notificationDenyReason}
                    multiline
                    onChange={setNotificationDenyReason}
                    controlClass='report-additional' />

                <FillButton onClick={approveNotification}>
                    Approve notification
                    </FillButton>

                <FillButton onClick={denyNotification} disabled={!notificationDenyReason}>
                    Deny notification
                </FillButton>

                <FillButton onClick={() => setNotificationModalOpen(false)}>
                    Cancel
                </FillButton>

            </Modal>

        </div>
    );

    async function applyPin() {
        await Api.Posts.SetPinned(props.id, !isPinned);

        window.location.reload();
    }

    function createTitle() {
        switch (props.type) {
            default:
                return props.title;

            case 'sell-event-v1':
            case 'buy-event-v1':
                return `${props.itemCount} x ${props.title}`;

            case 'housing-room-available':
            case 'housing-looking-for-room':
                return props.location;

            case 'travel-lift':
            case 'travel-bus':
            case 'travel-train':
            case 'travel-other':
                return `£${props.itemCost}`;
        }
    }

    function getPreTitle() {

        switch (props.type) {
            case 'post':
                return 'Post notification text';

            case 'sell-event-v1':
            case 'sell-other':
                return 'Selling';

            case 'buy-event-v1':
            case 'buy-other':
                return 'Buying';

            case 'travel-lift':
                return 'Lift Share';

            case 'travel-bus':
                return 'Bus';

            case 'travel-train':
                return 'Train';

            case 'travel-other':
                return 'Other Travel';

            case 'lost':
                return 'Lost';

            case 'found':
                return 'Found';

            case 'housing-room-available':
                return 'Room Available';

            case 'housing-looking-for-room':
                return 'Looking for Room';
        }
    }

    function getTitleImage() {
        switch (props.type) {
            case 'sell-event-v1':
            case 'sell-other':
                return postSell;

            case 'buy-event-v1':
            case 'buy-other':
                return postBuy;

            case 'travel-lift':
                return postTravelLift;

            case 'travel-bus':
                return postTravelBus;

            case 'travel-train':
                return postTravelTrain;

            case 'travel-other':
                return postTravelOther;

            case 'lost':
                return postLost;

            case 'found':
                return postFound;

            case 'housing-room-available':
                return postHousingAvailable;

            case 'housing-looking-for-room':
                return postHousingLooking;
        }
    }

    function renderTypeContent() {
        switch (props.type) {
            default:
                return <div>ERROR</div>;

            case 'post':
            case 'housing-room-available':
            case 'housing-looking-for-room':
                return null;

            case 'sell-event-v1':
            case 'sell-other':
            case 'buy-event-v1':
            case 'buy-other':
                return renderBuySell();

            case 'lost':
            case 'found':
                return renderLostFound();

            case 'travel-lift':
            case 'travel-bus':
            case 'travel-train':
            case 'travel-other':
                return renderTravel();
        }
    }

    function renderBuySell() {
        const hasEndTime = props.type === 'buy-event-v1' || props.type === 'sell-event-v1';
        const hasCost = props.type === 'sell-event-v1' || props.type === 'sell-other';
        const hasCount = props.type === 'buy-event-v1';

        return (
            <>
                {hasEndTime && <div className='post-content-row'>
                    <AccessTime className='post-content-row-icon' />
                    <div className='post-content-row-text'>
                        {props.endTime}
                    </div>
                </div>}
                {hasCost && <div className='post-content-row'>
                    <Payment className='post-content-row-icon' />
                    <div className='post-content-row-text'>
                        £{props.itemCost}
                    </div>
                </div>}
                {hasCount && <div className='post-content-row'>
                    <div className='post-content-row-icon'>#</div>
                    <div className='post-content-row-text'>
                        {props.itemCount}
                    </div>
                </div>}
            </>
        )
    }

    function renderLostFound() {
        return (
            <>
                <div className='post-content-row'>
                    <LocationOn className='post-content-row-icon' />
                    <div className='post-content-row-text'>
                        {props.location}
                    </div>
                </div>
                <div className='post-content-row'>
                    <AccessTime className='post-content-row-icon' />
                    <div className='post-content-row-text'>
                        {props.endTime}
                    </div>
                </div>
            </>
        )
    }

    function renderTravel() {
        return (
            <>
                <div className='post-content-row'>
                    <LocationOn className='post-content-row-icon' />
                    <div className='post-content-row-text'>
                        {props.location}
                    </div>
                    <ArrowForward className='post-content-row-icon-second' />
                    <div className='post-content-row-text'>
                        {props.endLocation}
                    </div>
                </div>
                <div className='post-content-row'>
                    <AccessTime className='post-content-row-icon' />
                    <div className='post-content-row-text'>
                        {props.endTime}
                    </div>
                </div>
            </>
        )
    }

    async function deletePost() {
        await Api.Posts.DeletePost(props.id, deleteReason, deleteAdditionalReason);

        window.location.reload();
    }

    async function approveNotification() {
        await Api.Posts.SendNotification(props.id);

        window.location.reload();
    }

    async function denyNotification() {
        await Api.Posts.DenyNotification(props.id, notificationDenyReason);

        window.location.reload();
    }
};
