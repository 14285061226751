import './App.css';

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import PrivateRoute from './components/PrivateRoute';
import LoggedInRoot from './scenes/LoggedInRoot';
import LoginPage from './scenes/LoginPage';

function App() {
  return (
    <Router>

      <Switch>

        <Route path="/login">
          <LoginPage />
        </Route>

        <PrivateRoute path='/'>
          <LoggedInRoot />
        </PrivateRoute>

      </Switch>

    </Router>
  );
}

export default App;
