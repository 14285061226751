import moment from 'moment';

import { ApiDelete, ApiGet, ApiPost, ApiPostForm, GetImageInfoFromImage } from '../helpers';

export function GetPosts(filters) {
    const queryParams = {};

    const keys = Object.keys(filters);

    for (let i = 0; i < keys.length; i++) {
        let value = filters[keys[i]];

        if (value === undefined) {
            continue;
        }

        if (moment.isMoment(value)) {
            value = value.valueOf();
        }
        queryParams[keys[i]] = value;
    }

    return ApiGet('posts', queryParams);
}

export function GetPost(id) {
    return ApiGet(`posts/${id}`);
}

export function GetPostReactions(id) {
    return ApiGet(`posts/${id}/reactions`);
}

export function GetComments(filter) {
    return ApiPost(`posts/comments`, {}, filter);
}

export function GetComment(commentId) {
    return ApiGet(`posts/comments/${commentId}`, {});
}

export function GetCommentsForPost(id, before) {
    const queryParams = {};

    if (before) {
        queryParams.before = before.valueOf();
    }

    return ApiGet(`posts/${id}/comments`, queryParams);
}

export function GetCommentReactions(postId, commentId) {
    return ApiGet(`posts/${postId}/comments/${commentId}/reactions`);
}

export function CreatePost(post, images) {
    const formData = new FormData();

    for (let i = 0; i < images.length; i++) {
        const image = images[i];

        const imageInfo = GetImageInfoFromImage(image);

        formData.append(`images`, imageInfo);
    }

    formData.append('post', JSON.stringify(post));

    return ApiPostForm(`posts`, {}, formData);
}

export function DeletePost(postId, reason, additionalReason) {

    return ApiDelete(`posts/${postId}`, {}, { reason, additionalReason });
}

export function ReactToPost(postId, reaction) {
    return ApiPost(`posts/${postId}/reactions`, {}, { reaction: reaction });
}

export function AddCommentToPost(comment) {

    return ApiPost(`posts/${comment.postId}/comments`, {}, comment);
}

export function ReactToComment(postId, commentId, reaction) {

    return ApiPost(`posts/${postId}/comments/${commentId}`, {}, { reaction: reaction });
}

export function SetPinned(postId, pinned) {

    return ApiPost(`posts/${postId}/setPinned`, {}, { pinned: pinned });
}

export function SendNotification(postId) {

    return ApiPost(`posts/${postId}/sendNotification`);
}

export function DenyNotification(postId, reason) {

    return ApiPost(`posts/${postId}/denyNotification`, {}, { reason: reason });
}

export function DeleteComment(commentId) {

    return ApiDelete(`posts/comment/${commentId}`, {});
}
