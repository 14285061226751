import "./ReportList.css";

import React, { useState } from "react";

import Checkbox from "../../components/Checkbox";

import ReportListAll from "./ReportListAll";

export default function ReportList() {
  const [activeReports, setActiveReports] = useState(true);

  return (
    <div className="report-list-container">
      <Checkbox
        name="show-active-reports"
        label="Show only active reports"
        onChange={setActiveReports}
        checked={activeReports}
      />
      <ReportListAll onlyActive={activeReports} showActions={true} />
    </div>
  );
}
