import './MultipleSelect.css';

import classNames from 'classnames';
import React from 'react';

export default function MultipleSelect(props) {

    const {
        label,
        name,
        className,
        options,
        onChange,
        ...rest
    } = props;

    let classes = { 'multiple-select-container': true };
    if (className) {
        classes[className] = true;
    }

    return <div className={classNames(classes)}>
        {label && <label htmlFor={name}>{label}</label>}

        <select
            {...rest}
            name={name}
            id={name}
            multiple
            onChange={() => {
                const element = document.getElementById(name);
                onChange(Array.from(element.selectedOptions).map(o => o.value));
            }}>

            {props.options.map(o =>
                <option
                    value={o.id}
                    key={o.id}>

                    {o.name}

                </option>)}
        </select>
    </div>;
}
