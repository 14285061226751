import './AutoCompleteList.css';

import React, { useEffect, useState } from 'react';

import { Api } from '../../api';
import SingleSelect from '../../components/SingleSelect';
import TextInputWithTitle from '../../components/TextInputWithTitle';

const FIELD_WITH_UNI = ['event-name'];

export default function AutoCompleteList(props) {

    const fieldList = [
        { id: undefined, name: 'All' },
        { id: 'lost-found-category', name: 'Lost & Found' },
        { id: 'item-name', name: 'Buy & Sell Item Name' },
        { id: 'event-name', name: 'Event Name' },
        { id: 'course', name: 'Course' },
        { id: 'post-q-a', name: 'Q&A Categories' },
    ];

    const stateList = [
        { id: undefined, name: 'All' },
        { id: 'approved', name: 'Approved' },
        { id: 'banned', name: 'Banned' },
        { id: 'pending', name: 'Pending Approval' },
    ];

    const [universityList, setUniversityList] = useState([]);
    const [hasUniversityList, setHasUniversityList] = useState(false);

    const [field, setField] = useState(undefined);
    const [state, setState] = useState('pending');
    const [search, setSearch] = useState('');
    const [university, setUniversity] = useState(null);

    const [autoCompletes, setAutoCompletes] = useState([]);

    useEffect(() => {
        if (!hasUniversityList) {
            Api.GetUniversities()
                .then(result => {
                    setUniversityList(result.data);
                    setHasUniversityList(true);
                });
        }
    }, [hasUniversityList]);

    function loadData() {

        if (!hasUniversityList) {
            //return;
        }

        Api.Data.GetAutocompleteData(
            field === 'All' ? undefined : field,
            state === 'All' ? undefined : state,
            university ? university : undefined,
            search)
            .then(result => {

                setAutoCompletes(result.data);
            });
    };

    useEffect(loadData, [field, state, search]);

    const requiresUni = FIELD_WITH_UNI.includes(field);

    function setStatus(id, state) {
        Api.Data.SetAutocompleteEntryStatus(id, state).then(() => loadData());
    };

    return (
        <div className='autocomplete-list-container'>

            <div className='autocomplete-filter-container'>

                <SingleSelect
                    className='report-control'
                    name='field'
                    label='Field'
                    options={fieldList}
                    value={field}
                    onChange={setField} />

                <SingleSelect
                    className='report-control'
                    name='state'
                    label='State'
                    options={stateList}
                    value={state}
                    onChange={setState} />

                {requiresUni && false &&
                    <SingleSelect
                        className='report-control'
                        name='university'
                        label='University'
                        options={universityList}
                        value={university}
                        onChange={setUniversity} />}

                <TextInputWithTitle
                    className='report-control'
                    name='search'
                    label='Search'
                    value={search}
                    onChange={setSearch} />

            </div>

            <table className='autocomplete-list-table'>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Field</th>
                        <th>State</th>
                        <th>University Group</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    {autoCompletes.map(u => {

                        const uni = universityList.find(e => e.id === u.universityGroupId);

                        return <tr key={u.text + u.field + u.universityGroupId}>

                            <td>{u.text}</td>
                            <td>{fieldList.find(f => f.id === u.field).name}</td>
                            <td>{u.state}</td>
                            <td>{u.universityGroupId ? uni ? uni.name : u.universityGroupId : ''}</td>
                            <td>{renderButtonsForRow(u)}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    );

    function renderButtonsForRow(r) {
        const showApprove = r.state === 'pending' || r.state === 'banned';
        const showBan = r.state === 'pending' || r.state === 'approved';

        return <>

            {showApprove && <button onClick={() => setStatus(r.id, 'approved')}>Approve</button>}

            {showBan && <button onClick={() => setStatus(r.id, 'banned')}>Ban</button>}

        </>;
    }
};
