import './CommentList.css';

import React, { PropTypes, useEffect, useState } from 'react';

import { Api } from '../../api';
import Comment from './Comment';
import CommentListFilter from './CommentListFilter';

export default function CommentList(props) {
    const [comments, setComments] = useState([]);

    const [filter, setFilter] = useState({ activeReports: true });

    const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);

    function refreshData() {
        const finalFilter = Object.assign({}, filter, props.filter);
        Api.Posts.GetComments(finalFilter)
            .then(result => setComments(result.data));
    };

    async function loadNextPage() {
        if (isLoadingNextPage) {
            return;
        }
        setIsLoadingNextPage(true);

        const earlyDate = comments.length > 0 ? comments[comments.length - 1].createTime : undefined;

        const finalFilter = Object.assign({
            before: earlyDate,
        }, filter, props.filter);
        const result = await Api.Posts.GetPosts(finalFilter);

        const newPosts = [
            // Copy all existing comments that we didn't receive a new copy of
            ...comments.filter(p => result.data.find(r => r.id === p.id) === undefined),
            // Include new items
            ...result.data]
            .sort((a, b) => b.createTime - a.createTime);

        setComments(newPosts);

        setIsLoadingNextPage(false);
    }

    function _onScroll(event) {
        const el = document.getElementById('post-list-list');
        if (el.scrollHeight - el.scrollTop - (el.clientHeight * 2) < 0) {
            loadNextPage();
        }
    }

    useEffect(refreshData, [filter]);

    return (
        <div className='comment-list-container'>

            <CommentListFilter onFilterChange={setFilter} />

            <div id='comment-list-list' className='comment-list-list' onScroll={_onScroll}>

                {comments.map(p => <Comment {...p} key={p.id} listMode={true} />)}

            </div>
        </div>
    );
}