// import "./UniversityList.css";
import React, { useEffect, useState } from "react";
import { Api } from "../../api";
import { Modal } from "@mui/material";

export default function UniversityList(props) {
  const [universities, setUniversities] = useState([]);
  const [universitySearch, setUniversitySearch] = useState("");
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [locations, setLocations] = useState([]);
  const [isUpdateLocationModalOpen, setIsUpdateLocationModalOpen] =
    useState(false);
  const [updateLocationForm, setUpdateLocationForm] = useState({
    locationId: 0,
    locationName: "",
  });

  function loadUniversities() {
    // TODO: add pagination
    Api.GetUniversities()
      .then((result) => {
        setUniversities(result.data);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }

  function loadLocations() {
    Api.Locations.GetLocations()
      .then((result) => {
        setLocations(result.data);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }

  function updateUniversityLocationWithIdOrName() {
    const locationId = locations.find(
      (l) => l.location.toLowerCase() === updateLocationForm.name.toLowerCase()
    )?.id;

    Api.UpdateUniversityLocation(
      selectedUniversity?.id,
      locationId,
      updateLocationForm.name
    )
      .then(() => {
        loadUniversities();
        loadLocations();
        setIsUpdateLocationModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  }

  useEffect(() => {
    loadUniversities();
    loadLocations();
  }, []);

  return (
    <div className="universities-list-container">
      <div
        className="universities-list-list"
        style={{ maxHeight: "90vh", overflowY: "auto" }}
      >
        <input
          type="search"
          placeholder="Search universities"
          name="university-search"
          id="university-search"
          value={universitySearch}
          onChange={(ev) => setUniversitySearch(ev.target.value)}
          style={{ width: "100%" }}
        />
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>University</th>
              <th>Location</th>
              <th style={{ textAlign: "end" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {universities
              .filter(
                (u) =>
                  `${u.name}${u.location}`
                    ?.toLowerCase()
                    ?.includes(universitySearch.trim().toLowerCase()) ?? true
              )
              .map((u, i) => (
                <tr key={i}>
                  <td>{u.id}</td>
                  <td>{u.name}</td>
                  <td>{u.location}</td>
                  <td style={{ textAlign: "end" }}>
                    <button
                      onClick={() => {
                        setSelectedUniversity(u);
                        setUpdateLocationForm({
                          locationId: u.locationId,
                          locationName: u.location,
                        });
                        setIsUpdateLocationModalOpen(true);
                      }}
                    >
                      Update Location
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <Modal
        open={isUpdateLocationModalOpen}
        onClose={() => setIsUpdateLocationModalOpen(false)}
      >
        <div
          className="location-list-create"
          style={{
            backgroundColor: "white",
            textAlign: "center",
            paddingTop: "3rem",
            paddingBottom: "3rem",
          }}
        >
          <h1>Update Location for {selectedUniversity?.name ?? ''}</h1>

          <label>
            Location
            <input
              list="locations"
              type="text"
              value={updateLocationForm.name}
              onChange={(v) =>
                setUpdateLocationForm({
                  ...updateLocationForm,
                  name: v.target.value,
                })
              }
            />
          </label>
          <datalist id="locations">
            {locations.map((l, i) => (
              <option key={i} value={l.location} />
            ))}
          </datalist>

          <br />
          <br />
          <button onClick={() => updateUniversityLocationWithIdOrName()}>
            Set Location
          </button>
        </div>
      </Modal>
    </div>
  );
}
