import classNames from 'classnames';
import React from 'react';

export default function DateInputWithTitle(props) {

    const {
        onChange,
        className,
        name,
        label,
        ...rest
    } = props;

    let classes = { 'date-input-container': true };
    if (className) {
        classes[className] = true;
    }

    return (
        <div className={classNames(classes)}>

            {label && <div className='date-input-label'>{label}</div>}

            <input
                type='date'
                name={name}
                id={name}
                {...rest}
                onChange={() => {
                    const element = document.getElementById(name);
                    onChange(element.value);
                }} />
        </div>);
}
